import React, { useState, useEffect } from "react"
import { WPApi } from "@api"

const PurchaseCompleted = ({ order }) => {
  const [state, setState] = useState({ partners: [] })
  let affiliate = localStorage.getItem("withAffiliate")
  console.log("state", state)
  useEffect(() => {
    if (state.partners.length < 1) {
      console.log("eeeee")
      WPApi.getAllPartners()
        .then(data => {
          console.log("aaaaaa", data)
          // setState({
          // 	partners: data
          // })
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [state.partners])
  useEffect(() => {
    if (order.coupon_lines.length > 0 && affiliate == "true") {
      WPApi.createReferral(
        order.coupon_lines[0].code,
        order.discount_total * 0.5,
        order.id
      ).then(data => {})
    }
  }, [order])

  return (
    <div className="summary">
      <div className="summary-stats">
        <div className="row">
          <div className="col">
            <h3>
              Numer zamówienia: <strong>{order.id}</strong>
            </h3>
          </div>
          <div className="col">
            <h3>
              Status: <strong>Gotowe do wysyłki</strong>
            </h3>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PurchaseCompleted
