import { WPApi, PayUApi } from "@api"

const onInit = ctx => {
  const { search } = ctx.props.location
  const paymentId = Number(search.replace("?id=", ""))

  if (!Number.isNaN(paymentId)) {
    // jest inny niż completed
    return WPApi.searchByPaymentId(paymentId)
      .then(([order, ...rest]) => {
        if (!order) {
          return Promise.resolve(true)
        }
        if (order.status === "processing") {
          return PayUApi.getTransactionStatus(order.meta_data[0].value)
            .then(data => {
              // można założyć że data nie będzie miała orders
              // ktore jest array
              if (Array.isArray(data.orders) && data.orders.length === 1) {
                const { status } = data
                if (status.statusCode === "SUCCESS") {
                  return WPApi.changeOrder(paymentId)
                    .then(data => {
                      ctx.setState({
                        imBusy: false,
                        order: data,
                      })
                      return true
                    })
                    .catch(err => {
                      ctx.setState({
                        imBusy: false,
                        order: order,
                        error: err,
                      })
                      return err
                    })
                } else {
                  alert("Wystąpił problem z płatnością")

                  // jest inny niż completed
                  // show error - błąd z transakcją oraz treść błędu
                  ctx.setState({
                    imBusy: false,
                    order: order,
                  })
                  return Promise.resolve(true)
                }
              } else {
                // show error - bład spójności danych
                ctx.setState({
                  imBusy: false,
                  error: data,
                })
                return data
              }
            })
            .catch(err => {
              ctx.setState({
                imBusy: false,
                order: order,
                error: err,
              })
              return err
            })
        } else {
          // status inny niż processing
          ctx.setState({
            imBusy: false,
            order: order,
          })
          return order
        }
      })
      .catch(error => {
        ctx.setState({
          imBusy: false,
          error: error,
        })
        return error
      })
  } else {
    return Promise.resolve(true)
  }
}

export { onInit }
